.header-menu-mobile
	@apply xl:hidden
	&[data-step-index="0"]
		.close
			i
				&::before
					content: '\f00d'
	@media (max-width: 1199.98px)
		@apply fixed top-0 left-0 w-full h-[100dvh] z-[999] bg-white block
		@apply pointer-events-none translate-x-full overflow-hidden
		+transition-base()
	.header-title-close
		background: linear-gradient(90deg,#fdf0de,#fdf0dd 50.76%,#fbdad0)
	.header-top
		@apply flex items-start px-[15px] gap-[15px] mt-5
	.header-menu
		&>ul
			&>li
				&[class*="has-children"]
					&>a
						&::after
							content: '\f054'
							@apply inline-block font-awesome text-xl
		ul
			li
				&>a
					@apply flex items-center px-[15px] py-5 text-[18px] font-light border-b border-neutral-100 justify-between
		.menu-list
			a
				&::after
					content: '\f054'
					@apply inline-block font-awesome text-xl
	.menu-mega-main
		@apply fixed top-[50px] translate-x-full w-screen h-[calc(100svh-50px)] bg-white z-10 left-0 transition-all duration-300
		&.active
			@apply translate-x-0
	.menu-sub-list
		@apply w-full
		ul
			@apply w-full
	.tab-content-menu
		@apply fixed top-[0px] translate-x-full w-screen h-[calc(100svh-0px)] bg-white z-10 left-0 transition-all duration-300 p-5
		@apply block #{!important}
		.menu-sub-content
			@apply hidden
		&.active
			@apply translate-x-0


body
	&.isOpenMenu
		.header-menu-mobile
			@apply pointer-events-auto translate-x-0
