.staff-detail
	.staff-tab-box
		.wrap
			ul
				li
					&.active
						a
							@apply bg-Primary-6 text-white
					a
						@apply p-3 text-xl font-medium text-Primary-#02 bg-Neutral-100 px-5 rounded-full
