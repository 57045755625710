section.section-recruitment-list.section-3
	table
		@apply w-full
		th,td
			@apply border border-white
		thead
			tr
				th
					@apply text-xl text-white bg-Primary-6 font-bold px-5 py-3
					&:nth-child(2)
						@apply text-left
					&:nth-child(1)
						@apply text-center
		tbody
			tr
				&:nth-child(odd)
					@apply bg-Neutral-100
				td
					@apply text-base px-5 py-3
					&:nth-child(1)
						@apply text-center
					&:nth-child(2)
						@apply text-left
	&.section-3
		table
			tbody
				tr
					&:nth-child(n+5)
						@apply hidden
