.section-home-tabs
	@apply relative z-2
	&:not(.not-bg)
		&::before
			content: ''
			background-image: url('../img/5.png')
			background-size: 100% 275px
			background-repeat: no-repeat
			background-position: center top
			@apply absolute top-0 left-0 w-full rem:h-[275px] pointer-events-none -z-1
