.section-page-faq
	.wrap-item-toggle
		.item-toggle
			@apply cursor-pointer transition-all duration-300
			.title
				@apply rem:min-h-[80px] flex items-center gap-2  relative
				@apply border-b border-transparent transition-all duration-300 pr-5
				&.active
					@apply text-Primary-6
					&::after
						@apply rotate-180
				&::after
					content: "\f078"
					@apply font-awesome text-xl absolute font-light right-0
			.content
				@apply gradient-linear-2 px-4 py-3 rounded-lg
				@apply hidden py-5
				.wrap
					@apply flex flex-wrap gap-base
					.img
						@apply md:w-[40%] w-full
					.format-content
						@apply flex-1
