@layer components
	.title-style-primary
		@apply bg-Primary-6 text-white text-xl md:text-3xl lg:text-40 px-10 rounded-full py-2 w-fit font-light text-center
	.item-comp-service
		&:hover
			&::before
				@apply h-[50%]
		&::before
			content: ''
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%)
			@apply absolute bottom-0 left-0 w-full rem:h-[160px] transition-all duration-300 z-2 pointer-events-none
	.item-comp-real-showcase
		.line-split
			@apply relative flex items-center justify-center z-10
			&::before
				content: ''
				@apply w-5 h-5 rounded-full bg-white border-4 border-Neutral-300 absolute
		.img
			.tag
				@apply bg-Primary-#05 text-white rem:rounded-b-[12px] absolute top-0 py-1 px-4 left-1/2 -translate-x-1/2
	.tabslet-tab-style-1
		@apply flex items-center lg:gap-12 gap-5 overflow-auto py-2
		li
			&.active, &[class*='current']
				a
					@apply text-Primary-6
					&::before
						@apply opacity-100
			a
				@apply font-light relative whitespace-nowrap
				&::before
					content: ''
					@apply absolute top-full mt-2 bg-Primary-6 h-[1px] w-full opacity-0
	.popup-style-primary
		.carousel__button.is-close
			@apply top-0 bg-Primary-6 rounded-none w-12.5 h-12.5 p-2
		textarea
			@apply resize-none rem:h-[150px]
	.box-navigation
		border: 1px solid #dcdcdc
		@apply rounded-lg overflow-hidden bg-white
		@media (max-width: 1023.98px)
			@apply fixed top-0 left-0 w-[100vw] h-[100svh] overflow-y-auto z-[999] bg-white translate-x-full transition-all duration-300
		&.active
			@apply translate-x-0
		.list-navigation
			&>ul
				@apply flex flex-col px-5
				&>li
					&:last-child
						&>a
							@apply border-b-0
				li

					&[class*='current'],&.active
						&>a
							@apply text-Primary-6
							&::after
								content: '\f068'
						&>ul
							@apply block
					&[class*='has-children']
						&>a
							&::after
								content: '\2b'
								@apply font-awesome font-light
					a
						@apply border-b border-[#DCDCDC]
						@apply py-3 flex items-center justify-between hover:text-Primary-6
					ul
						@apply hidden px-3 bg-[#F6F6F6]
	#popup-consultation,#popup-recruitment
		@apply rem:max-w-[1160px] rem:rounded-[24px] p-3 w-full
		.carousel__button.is-close
			@apply top-4 right-4 bg-Primary-6 rounded-full w-10 h-10
	.form-group-style-primary
		input:not([type="file"])
			@apply w-full rem:h-[56px] rounded-full border border-Neutral-400 px-5 font-light
		textarea
			@apply w-full rem:h-[126px] rem:rounded-[16px] border border-Neutral-400 px-5 py-4 font-light resize-none
		.wpcf7-not-valid-tip
			@apply pl-5
	.wpcf7-not-valid-tip
		@apply text-left pt-2 block text-sm
	.item-product-main
		@apply rounded-lg overflow-hidden border-Primary-6 #{!important}
