footer
	background: linear-gradient(90deg, #FDF0DE 0%, #FDF0DD 50.76%, #FBDAD0 100%)
	border-top: 2px solid #AF9481
	@apply pt-15 pb-10
	.footer-logo
		img
			@apply max-w-full
	.footer-social
		@apply flex items-start gap-5
		li
			img
				@apply h-5 w-auto object-contain rem:max-w-[23px]
	.title-footer
		@apply text-[18px] lg:text-lg font-medium mb-5 lg:mb-3 leading-none
	.list-footer
		@apply flex flex-col gap-3
		li
			a
				+hover-underline(95%,#ff9612)
				@apply text-base font-light hover:text-Primary-6 pb-2
	.item-contact
		i.fa-phone-volume
			@apply rotate-[-45deg]
	.col
		@media (max-width: 767.98px)
			&:nth-child(1)
				order: 3
			&:nth-child(2)
				order: 4
			&:nth-child(3)
				order: 1
			&:nth-child(4)
				order: 2
.tool-cta
	@apply z-[999]
	.button-to-top
		@apply hidden
		&.active
			@apply block
	.btn-cta
		@apply px-3
		img
			@apply max-w-full
	.btn-pulse
		&::after
			content: ''
			box-shadow: 0 0 0 0 rgba(255, 89, 0, 0.859)
			animation: pulse-red 2s infinite
			@apply absolute-full rem:rounded-[50px]
@keyframes pulse-red
	0%
		transform: scale(0.95)
		box-shadow: 0 0 0 0 rgba(255, 89, 0, 0.6)
	70%
		transform: scale(1)
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0)
	100%
		transform: scale(0.95)
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0)
