.section-about-value
	.wrap-item-toggle
		.item-toggle
			@apply py-3 border-b border-Neutral-100
			.title
				@apply flex items-center gap-2  relative text-xl
				@apply transition-all duration-300 pr-5 cursor-pointer
				&.active
					@apply text-Primary-6
					&::after
						@apply rotate-180
				&::after
					content: "\f078"
					@apply font-awesome text-xl absolute font-light right-0
			.content
				@apply py-3 rounded-lg hidden
