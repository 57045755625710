section.section-product-detail.section-tabslet
	ul.tabslet-tab
		@apply overflow-x-auto flex gap-5
		li
			&.active
				a
					@apply bg-Primary-6 text-white
			a
				@apply text-Neutral-500 bg-neutral-100 h-15 flex-center text-base md:text-xl px-5 font-bold hover:bg-Primary-6 hover:text-white transition-all duration-300 rounded-full
	.tabslet-content
		@apply bg-Neutral-100 lg:p-10 p-5

