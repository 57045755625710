.contact-page
	.text-normal
		font-size: 16px
		line-height: 24px
		font-weight: 400
		font-style: normal

	.sub-title
		font-size: 24px
		line-height: 32px
		font-weight: 700

	.title
		font-size: 40px
		line-height: 48px
		font-weight: 700
		color: #F15A22

	.form-group
		background-color: #fff
		border: 1px solid #E0E0E0
		padding: 12px 20px

	.btn-primary
		background-color: #fff
		color: #F15A22
		border: 1px solid #F15A22
		padding: 12px 20px
		border-radius: 4px
		font-size: 16px
		line-height: 24px
		font-weight: 700
	.btn-primary:hover
		background-color: #F15A22
		color: #fff
