.bannerProduct
	.img
		background-image: url('../../img/bannerProduct.png')
		background-size: contain
		background-repeat: no-repeat
.breadcrumb-wrapper
	background-color: rgba(137,137 ,137 , 0.1)
section
	.box-image
		position: relative
		aspect-ratio: 16/9
		object-fit: cover
		border: 1px solid #DCDCDC
		&:hover .img
			transform: scale(1.05)
			transition: transform 0.4s ease-in-out
		img
			display: block
			width: 100%
			height: auto
			border-radius: 0.5rem
		.title
			&:hover
				color: #8A3F40

		.discount
			span
				color: white
				padding: 3px 10px
				font-weight: 200
				border-radius: 6px
		.price
			i
				color: white
