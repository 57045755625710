@layer utilities
	@import 'src/components/common/Title.sass'
	.section-py
		@apply py-10 lg:py-15 xl:py-15
	.no-gutters
		@apply mx-0
		> *
			@apply px-0
	.lozad
		opacity: 0
		filter: blur(10px)
		transition: .3s all ease-in-out
		&[data-loaded="true"]
			opacity: 1
			filter: blur(0)
	.text-gradient
		background: linear-gradient(100deg, #FFB240 -26.99%, #FF5A00 117.02%)
	.text-gradient-2
		background-clip: text
		background: linear-gradient(100deg, #FFB240 -26.99%, #FF5A00 117.02%)
		-webkit-background-clip: text
		-webkit-text-fill-color: transparent
	.gradient-primary
		background: linear-gradient(257deg, #FFB240 -22.21%, #FF5A00 91.97%)
	.hover-gradient-primary
		@apply relative z-2
		&::before
			content: ''
			z-index: -1
			transition: .3s all ease-in-out
			@apply absolute inset-0 gradient-primary rounded-full opacity-0
		&:hover
			&::before
				opacity: 1
	.gradient-sub-menu
		background: linear-gradient(90deg, #FFD2A3 0%, #FCDDBC 47.5%, #FFD2A3 100%)
	.gradient-linear-2
		background: linear-gradient(90deg, #FDF0DE 0%, #FDF0DD 50.76%, #FBDAD0 100%)
	.overlay-black-bottom
		@apply relative z-2
		&.none-radius
			&::before
				@apply rounded-none
		&::before
			content: ''
			border-radius: 0px 0px 20px 20px
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%)
			@apply absolute bottom-0 left-0 w-full h-[40%] z-1
	.img-max-width
		img
			@apply max-w-full #{!important}
