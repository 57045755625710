.section-about-history
	.swiper-pagination
		@apply absolute flex-center top-full mt-5
		.swiper-pagination-bullet
			@apply flex-1 w-auto h-1 bg-Primary-#04 rounded-full
			&.swiper-pagination-bullet-active
				@apply bg-Primary-6 rounded-full
	.wrap-slide-years
		@apply relative
		&::before
			content: ''
			@apply absolute top-[57%] md:top-[50%] -translate-y-1/2 left-0 w-full h-[1px] bg-Primary-#01
		.swiper-slide
			&:first-child
				.line-year
					.circle
						@apply bg-white border-Primary-#01
			&.swiper-slide-next
				.wrap-content
					.title-48
						@apply text-Primary-6
				.content
					.line-year
						.dot
							@apply bg-Primary-6
						.line
							@apply bg-gradient-to-b from-Primary-6 to-transparent #{!important}
			@media (max-width: 767.98px)
				.item-history
					.content
						@apply rem:-translate-y-[calc(65px-15px)]
			@screen md
				&:nth-child(odd)
					.item-history
						.content
							@apply rem:-translate-y-[calc(65px-15px)]
				&:nth-child(even)
					.item-history
						@apply flex flex-col-reverse
						.content
							@apply flex justify-end flex-col
							@apply rem:translate-y-[calc(65px-15px)]
						.wrap-content
							@apply pt-0 pb-10
						.line
							@apply bg-gradient-to-b from-Primary-#01 to-transparent
						.line-year
							@apply flex flex-col-reverse

