.section-recruitment-detail
	.box-recruitment-detail-item
		table
			tr
				@apply border-b border-Neutral-200
			td
				@apply py-4 first:whitespace-nowrap first:pr-5
.form-input-file
	@apply relative flex items-center
	@apply w-full rem:h-[56px] rounded-full border border-Neutral-400 px-5 font-light
	input[type="file"]
		@apply absolute-full text-transparent z-10
		&::-webkit-file-upload-button,&::file-selector-button
			@apply hidden #{!important}
	.wpcf7-form-control-wrap
		@apply static #{!important}
	&::before
		content: "Input file CV"
		@apply text-base text-Primary-6 font-bold left-5
	.file-name
		text-overflow: ellipsis
		@apply w-full h-full rounded-full border border-transparent px-5 font-light absolute-full mt-0 bg-white text-Primary-6
		@apply block px-5 line-clamp-1 place-content-center whitespace-nowrap


