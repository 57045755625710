@charset "UTF-8";
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
}

form.invalid .wpcf7-response-output {
  @apply text-red-600 border-red-600 bg-white !important;
}

.wpcf7-response-output {
  @apply text-green-400 border-green-400 bg-white border py-1 px-4 text-center mt-2 mb-0 ml-0 mr-0 text-sm !important;
}

.frm-submit {
  @apply relative;
}
.frm-submit .wpcf7-spinner {
  @apply absolute left-full top-1/2 -translate-y-1/2 ml-2;
}

.edit-link i:before {
  content: "✏️";
}

.wrap-button-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  @apply gap-5 xl:gap-0;
}
@media (max-width: 767.98px) {
  .wrap-button-slide.break {
    @apply mt-5;
  }
  .wrap-button-slide.break .btn-prev, .wrap-button-slide.break .btn-next {
    @apply translate-y-0 !important;
    @apply static !important;
  }
}
.wrap-button-slide.in-static .btn-prev, .wrap-button-slide.in-static .btn-next {
  @apply absolute top-1/2 -translate-y-1/2;
}
.wrap-button-slide.in-static .btn-prev {
  @apply left-0 ml-5 lg:ml-10 right-auto !important;
}
.wrap-button-slide.in-static .btn-next {
  @apply right-0 mr-5 lg:mr-10 left-auto !important;
}
.wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1279.98px) {
  .wrap-button-slide.none-static .btn-prev, .wrap-button-slide.none-static .btn-next {
    margin: 0 !important;
  }
}
@media (max-width: 1023.98px) {
  .wrap-button-slide.none-static .btn-prev {
    right: 94%;
  }
}
@media (max-width: 575.98px) {
  .wrap-button-slide.none-static .btn-prev {
    right: auto;
    left: 10px;
  }
}
@media (max-width: 1023.98px) {
  .wrap-button-slide.none-static .btn-next {
    left: 94%;
  }
}
@media (max-width: 575.98px) {
  .wrap-button-slide.none-static .btn-next {
    left: auto;
    right: 10px;
  }
}
.wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
  z-index: 9;
  cursor: pointer;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
}
.wrap-button-slide .btn-prev.swiper-button-lock, .wrap-button-slide .btn-next.swiper-button-lock {
  @apply hidden;
}
.wrap-button-slide .btn-prev {
  left: 15px;
}
@screen xl {
  .wrap-button-slide .btn-prev {
    margin-right: 1.5625rem;
    right: 100%;
    left: auto;
  }
}
.wrap-button-slide .btn-next {
  right: 15px;
}
@screen xl {
  .wrap-button-slide .btn-next {
    margin-left: 1.5625rem;
    left: 100%;
    right: auto;
  }
}

@media (max-width: 767.98px) {
  .btn-slide-mobile-half .btn-prev, .btn-slide-mobile-half .btn-next {
    z-index: 9;
    cursor: pointer;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-slide-mobile-half .btn-prev.swiper-button-lock, .btn-slide-mobile-half .btn-next.swiper-button-lock {
    @apply hidden;
  }
  .btn-slide-mobile-half .btn-prev {
    left: 20px;
  }
  .btn-slide-mobile-half .btn-next {
    right: 20px;
  }
}

.zoom-img {
  overflow: hidden;
}
.zoom-img:hover img {
  transform: scale(1.05) translateZ(0);
}
.zoom-img img {
  transition: 0.5s all ease-in-out !important;
}

.ring-food {
  animation: 1.5s ring-food infinite ease-in-out alternate;
}

.ring-chili {
  animation: 1.5s ring-chili infinite ease-in-out alternate;
}

.ring-food-footer {
  animation: 1.5s ring-food-footer infinite ease-in-out alternate;
}

@keyframes ring-chili {
  0% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
@keyframes ring-food-footer {
  0% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes ring-food {
  0% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(330deg);
  }
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
span.fa-exclamation-triangle {
  @apply text-red-500 text-sm font-primary py-2 !important;
}
span.fa-exclamation-triangle::before {
  display: none;
}

.btn.btn-default.frm-btn-reset {
  @apply hidden;
}

.frm-btnwrap, .frm-captcha {
  opacity: 0;
}

.wrap-form-submit {
  @apply flex items-center flex-wrap justify-between gap-5 md:gap-4;
}
.wrap-form-submit .frm-captcha, .wrap-form-submit .frm-btnwrap {
  opacity: 1;
}
.wrap-form-submit .frm-captcha {
  @apply flex flex-row-reverse items-center flex-1;
}
.wrap-form-submit .frm-captcha .RadCaptcha {
  width: fit-content !important;
  @apply relative;
}
.wrap-form-submit .frm-captcha .RadCaptcha > span {
  @apply absolute top-full left-0 text-red-500;
}
.wrap-form-submit .frm-captcha .RadCaptcha > div > div {
  @apply flex items-center;
}
.wrap-form-submit .frm-captcha .RadCaptcha img {
  width: 8.28125rem !important;
  height: 2.6041666667rem !important;
  @apply border border-[#EEEEEE] !important;
}
.wrap-form-submit .frm-captcha .RadCaptcha a.rcRefreshImage {
  @apply text-0 before:content-[""] before:font-awesome before:text-2xl before:mx-3 before:inline-block w-full h-full text-[#333333];
  @apply flex-center !important;
}
.wrap-form-submit .frm-captcha .frm-captcha-input {
  @apply flex-1;
}
.wrap-form-submit .frm-captcha .frm-captcha-input label {
  @apply hidden;
}
@media (max-width: 767.98px) {
  .wrap-form-submit .frm-btnwrap {
    @apply flex-[0_0_100%];
  }
}
.wrap-form-submit .frm-btnwrap label {
  @apply hidden;
}

.search-page {
  padding: 40px 0;
}
.search-page h1 {
  letter-spacing: -2px;
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  color: #333333;
}
@media (max-width: 1024px) {
  .search-page h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.search-page h1.center {
  text-align: center;
}
.search-page .btn-reindex {
  display: none;
}
.search-page .searchcontrols .form-group {
  position: static;
}
.search-page .searchcontrols .form-inline {
  position: relative;
}
.search-page .searchcontrols .form-inline:before {
  content: "";
  background-image: url("/Data/Sites/1/skins/default/img/icon/search.svg");
  @apply absolute w-6 h-6 flex-center top-1/2 right-4 brightness-0 -translate-y-1/2;
}
.search-page .searchcontrols .frm-btn {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}
.search-page input[type=text] {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 54px;
  border: 1px solid #dfe1e5;
  background-color: #fff;
  color: #333;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 22px;
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  @apply h-12.5;
}
.search-page input[type=submit] {
  @apply absolute top-0 right-0 h-full flex-center px-4 h-12.5 text-0;
}
.search-page .text-danger {
  margin-top: 20px;
  font-size: 15px;
  color: #333;
  font-style: italic;
  font-weight: 600;
}
.search-page .searchresultsummary {
  margin-bottom: 20px;
}
.search-page .searchresults {
  margin-top: 30px;
}
.search-page .searchresults .modulepager:first-child {
  display: none;
}
.search-page .searchresultlist {
  margin-bottom: 20px;
}
.search-page .searchresult {
  margin-bottom: 30px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  padding: 16px;
  border-radius: 8px;
}
.search-page .searchresult:last-child {
  margin-bottom: 0;
}
.search-page .searchresult h3 {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult h3 a {
  color: #1A0DAB;
  text-decoration: none;
  font-weight: 500;
}
.search-page .searchresult h3 a:hover {
  text-decoration: underline;
}
.search-page .searchresult .searchresultdesc {
  color: #545454;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult .searchterm {
  color: #6a6a6a;
  font-weight: bold;
  font-style: normal;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
}
.search-page .searchresult hr {
  display: none !important;
}

.loginpage header {
  display: none;
}
.loginpage footer {
  display: none;
}
.loginpage main {
  background-color: #F0F0F1;
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  padding-top: 0 !important;
  padding: 0 15px;
}
.loginpage .header-tools-mobile {
  display: none !important;
}
.loginpage .login-box {
  max-width: 690px;
  margin: 0 auto;
  width: 100%;
}
.loginpage .login-box .login-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-direction: column;
  font-size: 0;
  margin-bottom: 30px;
}
.loginpage .login-box .login-logo::before {
  content: "";
  display: inline-block;
  background-image: url("../img/logo-canh-cam.png");
  width: 267px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: 267px auto;
  margin-bottom: 15px;
}
.loginpage .login-box .loginstandard {
  background-color: #fff;
  box-shadow: 0, 4px, 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 0;
  padding: 40px 60px 30px;
  font-weight: 400;
  overflow: hidden;
  font-family: Roboto, sans-serif !important;
}
@media (max-width: 767.98px) {
  .loginpage .login-box .loginstandard {
    padding: 15px 20px;
  }
}
.loginpage .login-box .loginstandard .card-body .row.items-center {
  @apply flex-wrap mx-0;
}
.loginpage .login-box .loginstandard .card-body .row.items-center .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.loginpage .login-box .loginstandard .card-body .login-box-msg {
  display: none;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group {
  position: relative;
  margin-bottom: 1.25rem;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group .input-group-append {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #d1d1d1;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input {
  background: #f5f5f5;
  border-radius: 25px;
  height: 50px;
  border: 0;
  outline: none;
  box-shadow: 0 0 0 1px #f5f5f5;
  transition: 0.3s all ease-in-out;
  padding: 0 35px;
  font-size: 14px;
  width: 100%;
  border: 1px solid transparent;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input:hover, .loginpage .login-box .loginstandard .card-body .panel-body .input-group input:focus {
  border: 1px solid #f73936;
}
.loginpage .login-box .loginstandard .card-body .panel-body .icheck-primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-bottom: 20px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .btn-primary {
  background-color: #f73936;
  border-radius: 25px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  font-size: 20px;
  border: 0;
  outline: none;
  transition: 0.3s all ease-in-out;
}
.loginpage .login-box .loginstandard .card-body .panel-body .flex.justify-between.mt-3 {
  margin-top: 30px;
  font-size: 14px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .cart-login {
  display: none;
}
.loginpage .login-box .loginstandard .card-body span.alert {
  color: #f73936;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

.sitemap {
  @apply py-15;
}

.sitemap-heading h1 {
  @apply text-lg text-center uppercase mx-auto;
  @apply relative w-max max-w-full pb-1.5 before:absolute before:bottom-0 before:left-0 before:w-full before:h-[1px] before:bg-white;
}

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root a {
  @apply inline-flex items-center px-7 py-3 text-xl bg-blue-400 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-red-300;
}
.AspNet-TreeView .AspNet-TreeView-Root > a {
  @apply before:absolute before:left-full before:w-[120%] before:h-[1px] before:bg-gray-300 before:-z-1;
}
.AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
  @apply hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root li {
  @apply pl-3;
}
.AspNet-TreeView .AspNet-TreeView-Root li a {
  @apply bg-blue-400/[.9];
}
.AspNet-TreeView .AspNet-TreeView-Root li li a {
  @apply bg-blue-400/[.75];
}
.AspNet-TreeView .AspNet-TreeView-Root li li li a {
  @apply bg-blue-400/[.6];
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
  @apply before:absolute before:w-[1px] before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
  @apply before:top-full;
}
.AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
  @apply before:hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
  @apply mt-2 relative z-1;
  @apply after:absolute after:w-[1px] after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-gray-300 after:pointer-events-none;
}
.AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
  @apply relative;
  @apply before:absolute before:w-3 before:-z-1 before:h-[1px] before:bg-gray-300 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none;
}

.cmsadminpanel ul {
  display: flex;
  align-items: center;
}
.cmsadminpanel.isClose .navbar-nav {
  @apply hidden;
}
.cmsadminpanel.isClose #pullmenucms i::after {
  content: "Mở";
}
.cmsadminpanel #pullmenucms i::after {
  content: "Đóng";
}

.notfound .notfound-404 h1 {
  @apply bg-red-500 bg-none !important;
}
.notfound a {
  @apply bg-red-500 bg-none shadow-none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    scroll-padding-top: 100px;
  }
  html, body {
    font-size: 17px;
  }
  @screen md {
    html, body {
      font-size: 15px;
    }
  }
  @screen xl {
    html, body {
      font-size: 1vw;
    }
  }
  main {
    background-image: url(../img/bg-oracle.jpg);
  }
  .table-responsive {
    @apply overflow-auto;
  }
  @media (max-width: 767.98px) {
    .table-responsive table {
      width: 900px !important;
    }
  }
  .img-full img {
    @apply w-full h-full object-cover;
  }
  .img-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .img-ratio img, .img-ratio iframe {
    @apply absolute top-0 left-0 w-full h-full object-cover transition-all duration-300;
  }
  .img-ratio-center {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    @apply flex-center;
  }
  .img-ratio-center img, .img-ratio-center iframe {
    @apply absolute top-1/2 left-1/2 w-auto h-auto object-contain transition-all duration-300 max-w-full max-h-full -translate-x-1/2 -translate-y-1/2;
  }
  .div-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .div-ratio > div {
    @apply absolute top-0 left-0 w-full h-full transition-all duration-300;
  }
  body {
    @apply text-Primary-#02 text-base font-primary;
  }
  .row {
    @apply flex flex-wrap -mx-[7.5px] lg:-mx-5;
  }
  .col {
    @apply px-[7.5px] lg:px-5 w-full;
  }
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold;
  }
  img {
    @apply inline max-w-none;
  }
  a {
    @apply transition-all ease-linear duration-200;
  }
  p {
    @apply empty:hidden;
  }
  input {
    @apply outline-offset-0 shadow-none ring-0 !important;
  }
  .section {
    @apply py-12.5 lg:py-20;
  }
  .section-40 {
    @apply lg:py-10 py-6;
  }
  .margin-p p + p {
    @apply mt-5;
  }
  .overflow-scroll-1::-webkit-scrollbar {
    @apply w-[calc(6/1920*100rem)];
  }
  .overflow-scroll-1::-webkit-scrollbar-track {
    background-color: rgba(229, 229, 229, 0.2);
  }
  .overflow-scroll-1::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }
  body.disable-scroll {
    @apply overflow-hidden;
  }
  .format-content {
    @apply text-justify;
    @apply lg:text-base text-[16px];
  }
  .format-content img {
    @apply max-w-full;
  }
  .format-content img.aligncenter {
    @apply block w-fit mx-auto;
  }
  .format-content h1, .format-content h2, .format-content h3, .format-content h4, .format-content h5, .format-content h6 {
    @apply mb-5;
  }
  .format-content a {
    @apply text-Primary-6 hover:underline;
  }
  .format-content p {
    @apply mb-3 lg:text-base text-[16px] font-light;
  }
  .format-content ul {
    @apply list-disc pl-5 flex flex-col gap-2 mb-3;
  }
  .format-content ul li::marker {
    @apply text-Neutral-500;
  }
  .overlay-black {
    @apply relative z-2;
  }
  .overlay-black::before {
    content: "";
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%);
    @apply absolute top-0 left-0 w-full h-full -z-1 pointer-events-none;
  }
  .mb-base {
    @apply lg:mb-10 mb-8;
  }
  .gap-base {
    @apply lg:gap-10 md:gap-5 gap-[15px];
  }
  .post-nav {
    @apply mt-10;
  }
  .post-nav ul {
    @apply flex flex-center gap-3;
  }
  .post-nav ul li a, .post-nav ul li span {
    @apply w-12 h-12 bg-neutral-100 flex-center rounded-full text-center text-base hover:bg-Primary-6 hover:text-white transition-all duration-300;
  }
  .post-nav ul li.active span {
    @apply bg-Primary-6 text-white;
  }
}
@layer components {
  .title-style-primary {
    @apply bg-Primary-6 text-white text-xl md:text-3xl lg:text-40 px-10 rounded-full py-2 w-fit font-light text-center;
  }
  .item-comp-service:hover::before {
    @apply h-[50%];
  }
  .item-comp-service::before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    @apply absolute bottom-0 left-0 w-full rem:h-[160px] transition-all duration-300 z-2 pointer-events-none;
  }
  .item-comp-real-showcase .line-split {
    @apply relative flex items-center justify-center z-10;
  }
  .item-comp-real-showcase .line-split::before {
    content: "";
    @apply w-5 h-5 rounded-full bg-white border-4 border-Neutral-300 absolute;
  }
  .item-comp-real-showcase .img .tag {
    @apply bg-Primary-#05 text-white rem:rounded-b-[12px] absolute top-0 py-1 px-4 left-1/2 -translate-x-1/2;
  }
  .tabslet-tab-style-1 {
    @apply flex items-center lg:gap-12 gap-5 overflow-auto py-2;
  }
  .tabslet-tab-style-1 li.active a, .tabslet-tab-style-1 li[class*=current] a {
    @apply text-Primary-6;
  }
  .tabslet-tab-style-1 li.active a::before, .tabslet-tab-style-1 li[class*=current] a::before {
    @apply opacity-100;
  }
  .tabslet-tab-style-1 li a {
    @apply font-light relative whitespace-nowrap;
  }
  .tabslet-tab-style-1 li a::before {
    content: "";
    @apply absolute top-full mt-2 bg-Primary-6 h-[1px] w-full opacity-0;
  }
  .popup-style-primary .carousel__button.is-close {
    @apply top-0 bg-Primary-6 rounded-none w-12.5 h-12.5 p-2;
  }
  .popup-style-primary textarea {
    @apply resize-none rem:h-[150px];
  }
  .box-navigation {
    border: 1px solid #dcdcdc;
    @apply rounded-lg overflow-hidden bg-white;
  }
  @media (max-width: 1023.98px) {
    .box-navigation {
      @apply fixed top-0 left-0 w-[100vw] h-[100svh] overflow-y-auto z-[999] bg-white translate-x-full transition-all duration-300;
    }
  }
  .box-navigation.active {
    @apply translate-x-0;
  }
  .box-navigation .list-navigation > ul {
    @apply flex flex-col px-5;
  }
  .box-navigation .list-navigation > ul > li:last-child > a {
    @apply border-b-0;
  }
  .box-navigation .list-navigation > ul li[class*=current] > a, .box-navigation .list-navigation > ul li.active > a {
    @apply text-Primary-6;
  }
  .box-navigation .list-navigation > ul li[class*=current] > a::after, .box-navigation .list-navigation > ul li.active > a::after {
    content: "\f068";
  }
  .box-navigation .list-navigation > ul li[class*=current] > ul, .box-navigation .list-navigation > ul li.active > ul {
    @apply block;
  }
  .box-navigation .list-navigation > ul li[class*=has-children] > a::after {
    content: "+";
    @apply font-awesome font-light;
  }
  .box-navigation .list-navigation > ul li a {
    @apply border-b border-[#DCDCDC];
    @apply py-3 flex items-center justify-between hover:text-Primary-6;
  }
  .box-navigation .list-navigation > ul li ul {
    @apply hidden px-3 bg-[#F6F6F6];
  }
  #popup-consultation, #popup-recruitment {
    @apply rem:max-w-[1160px] rem:rounded-[24px] p-3 w-full;
  }
  #popup-consultation .carousel__button.is-close, #popup-recruitment .carousel__button.is-close {
    @apply top-4 right-4 bg-Primary-6 rounded-full w-10 h-10;
  }
  .form-group-style-primary input:not([type=file]) {
    @apply w-full rem:h-[56px] rounded-full border border-Neutral-400 px-5 font-light;
  }
  .form-group-style-primary textarea {
    @apply w-full rem:h-[126px] rem:rounded-[16px] border border-Neutral-400 px-5 py-4 font-light resize-none;
  }
  .form-group-style-primary .wpcf7-not-valid-tip {
    @apply pl-5;
  }
  .wpcf7-not-valid-tip {
    @apply text-left pt-2 block text-sm;
  }
  .item-product-main {
    @apply rounded-lg overflow-hidden border-Primary-6 !important;
  }
}
@layer utilities {
  .title-64 {
    @apply text-4xl md:text-5xl lg:text-6xl xl:text-64 font-bold uppercase leading-normal;
  }
  .title-40 {
    @apply text-3xl lg:text-5xl font-bold leading-tight;
  }
  .title-48 {
    @apply text-3xl md:text-5xl lg:text-6xl font-bold leading-tight;
  }
  .title-section {
    @apply px-10 py-3 text-white capitalize rounded-full inline-block bg-Primary-6;
  }
  .title-24 {
    @apply text-2xl text-Neutral-900 leading-tight;
  }
  .title-32 {
    @apply lg:text-32 text-2xl font-bold leading-tight;
  }
  .section-py {
    @apply py-10 lg:py-15 xl:py-15;
  }
  .no-gutters {
    @apply mx-0;
  }
  .no-gutters > * {
    @apply px-0;
  }
  .lozad {
    opacity: 0;
    filter: blur(10px);
    transition: 0.3s all ease-in-out;
  }
  .lozad[data-loaded=true] {
    opacity: 1;
    filter: blur(0);
  }
  .text-gradient {
    background: linear-gradient(100deg, #FFB240 -26.99%, #FF5A00 117.02%);
  }
  .text-gradient-2 {
    background-clip: text;
    background: linear-gradient(100deg, #FFB240 -26.99%, #FF5A00 117.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .gradient-primary {
    background: linear-gradient(257deg, #FFB240 -22.21%, #FF5A00 91.97%);
  }
  .hover-gradient-primary {
    @apply relative z-2;
  }
  .hover-gradient-primary::before {
    content: "";
    z-index: -1;
    transition: 0.3s all ease-in-out;
    @apply absolute inset-0 gradient-primary rounded-full opacity-0;
  }
  .hover-gradient-primary:hover::before {
    opacity: 1;
  }
  .gradient-sub-menu {
    background: linear-gradient(90deg, #FFD2A3 0%, #FCDDBC 47.5%, #FFD2A3 100%);
  }
  .gradient-linear-2 {
    background: linear-gradient(90deg, #FDF0DE 0%, #FDF0DD 50.76%, #FBDAD0 100%);
  }
  .overlay-black-bottom {
    @apply relative z-2;
  }
  .overlay-black-bottom.none-radius::before {
    @apply rounded-none;
  }
  .overlay-black-bottom::before {
    content: "";
    border-radius: 0px 0px 20px 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    @apply absolute bottom-0 left-0 w-full h-[40%] z-1;
  }
  .img-max-width img {
    @apply max-w-full !important;
  }
}
.btn {
  @apply cursor-pointer select-none transition-all duration-300 z-10;
}
.btn.btn-sw-next {
  @apply absolute top-1/2 right-[-40px] translate-y-[-50%];
}
.btn.btn-sw-prev {
  @apply absolute top-1/2 left-[-40px] translate-y-[-50%];
}
.btn.btn-style-primary {
  @apply px-6 py-2 rounded-full text-white flex-center gradient-primary;
}
.btn.btn-style-primary.big {
  @apply rem:h-[52px] text-lg;
}
.btn.btn-style-sw-3 {
  @apply flex-center w-12 h-12 bg-black/50 hover:bg-black;
}
.btn.btn-style-sw-3::before {
  @apply font-awesome text-white text-xl;
}
.btn.btn-style-sw-3.btn-prev::before {
  content: "\f053";
}
.btn.btn-style-sw-3.btn-next::before {
  content: "\f054";
}
.btn.btn-sw-1 {
  @apply flex-center w-13 h-13 lg:w-15 lg:h-15 rounded-full border border-white hover:border-Primary-6 hover:bg-Primary-6;
}
.btn.btn-sw-1.primary {
  @apply border-Primary-6;
}
.btn.btn-sw-1.primary.btn-prev::before, .btn.btn-sw-1.primary.btn-next::before {
  @apply text-Primary-6;
}
.btn.btn-sw-1.black {
  @apply border-Neutral-500 hover:border-Primary-6;
}
.btn.btn-sw-1.black.btn-prev::before, .btn.btn-sw-1.black.btn-next::before {
  @apply text-Neutral-500;
}
.btn.btn-sw-1:hover.btn-prev::before, .btn.btn-sw-1:hover.btn-next::before {
  @apply text-white;
}
.btn.btn-sw-1.btn-prev::before, .btn.btn-sw-1.btn-next::before {
  @apply font-thin font-awesome text-[18px] lg:rem:text-[32px] text-white transition-all duration-300;
}
.btn.btn-sw-1.btn-prev::before {
  content: "\f060";
}
.btn.btn-sw-1.btn-next::before {
  content: "\f061";
}
.btn.btn-outline {
  @apply border border-dashed border-Primary-6 rounded-full h-12 px-8 flex-center text-Primary-6 font-medium;
  @apply hover-gradient-primary hover:text-white;
}
.btn.btn-hotline img {
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}
.title-64 {
  @apply text-4xl md:text-5xl lg:text-6xl xl:text-64 font-bold uppercase leading-normal;
}

.title-40 {
  @apply text-3xl lg:text-5xl font-bold leading-tight;
}

.title-48 {
  @apply text-3xl md:text-5xl lg:text-6xl font-bold leading-tight;
}

.title-section {
  @apply px-10 py-3 text-white capitalize rounded-full inline-block bg-Primary-6;
}

.title-24 {
  @apply text-2xl text-Neutral-900 leading-tight;
}

.title-32 {
  @apply lg:text-32 text-2xl font-bold leading-tight;
}

.global-breadcrumb {
  @apply border-b border-Primary-#04;
}
.global-breadcrumb p {
  @apply h-[calc(48/1920*100rem)] flex items-center overflow-auto;
}
.global-breadcrumb p a:first-child {
  @apply text-0;
}
.global-breadcrumb p a:first-child::before {
  content: "\f015";
  @apply font-awesome text-sm;
}
.global-breadcrumb p a, .global-breadcrumb p span {
  @apply text-sm text-[#333333] whitespace-nowrap;
}
.global-breadcrumb p span.separator {
  @apply text-0 inline-flex;
}
.global-breadcrumb p span.separator::before {
  content: "/";
  @apply font-normal text-sm mx-2;
}

header {
  background: linear-gradient(90deg, #FDF0DE 0%, #FDF0DD 50.76%, #FBDAD0 100%);
  @apply sticky top-0 left-0 w-full z-[900];
}
header .header-language {
  @apply relative ml-6;
}
header .header-language:hover ul {
  @apply opacity-100 pointer-events-auto;
}
header .header-language .js-wpml-ls-legacy-dropdown {
  @apply w-auto !important;
}
header .header-language .js-wpml-ls-legacy-dropdown > ul > li > a {
  @apply flex items-center gap-2 rem:text-[14px] w-auto bg-transparent px-0 py-0 text-Primary-#02 border-none flex-row-reverse !important;
}
header .header-language .js-wpml-ls-legacy-dropdown > ul > li > a::after {
  @apply hidden;
}
header .header-language .js-wpml-ls-legacy-dropdown > ul > li > a::before {
  content: "\f0d7";
  @apply font-awesome rem:text-[8px] font-black;
}
header .header-language .js-wpml-ls-legacy-dropdown > ul > ul {
  @apply absolute top-full left-0 shadow-md opacity-0 pointer-events-none transition-all duration-300;
}
header .header-language .js-wpml-ls-legacy-dropdown > ul > ul > li > a {
  @apply flex items-center gap-2 rem:text-[14px] bg-white rounded-sm px-2 py-1 leading-none hover:text-Primary-6 hover:bg-neutral-50;
}
header .header-menu {
  @apply flex items-center lg:gap-3 xl:gap-6;
}
header .header-menu > ul {
  @apply flex items-center lg:gap-3 xl:gap-6;
}
header .header-menu > ul > li[class*=current] a {
  @apply text-white;
}
header .header-menu > ul > li[class*=current] a::before {
  @apply opacity-100;
}
header .header-menu > ul > li.dropdown-product .menu-mega-main {
  @apply opacity-0 pointer-events-none transition-all duration-300;
}
header .header-menu > ul > li.dropdown-product .menu-mega-main.active {
  @apply opacity-100 pointer-events-auto;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list {
  @apply absolute top-full left-0 w-full bg-white rem:min-h-[618px] px-10 py-7 rounded-b-lg shadow-lg flex;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .close {
  @apply absolute top-2 right-2 p-4 rem:w-[30px] rem:h-[30px] flex-center bg-[#FEF9F1] rounded-full hover:bg-Primary-6 transition-all duration-300 cursor-pointer;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .close:hover::before {
  @apply text-white;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .close::before {
  content: "\f00d";
  @apply font-awesome rem:text-[14px] text-Primary-6;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .tab-content-menu {
  @apply h-full flex flex-col;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-list {
  @apply w-[20%] pr-4 border-r border-Neutral-200;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-list ul {
  @apply flex flex-col gap-4;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-list ul li[class*=current] a {
  @apply text-Primary-6;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-list ul li a {
  @apply hover:text-Primary-6 transition-all duration-300 text-[#757679];
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-content {
  @apply w-[80%] pl-4 flex flex-col;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-content .tab-content {
  background: linear-gradient(90deg, rgba(253, 240, 222, 0.4) 0%, rgba(253, 240, 221, 0.4) 50.76%, rgba(251, 218, 208, 0.4) 100%);
  @apply flex-1 flex rounded-r-lg;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-content .menu-sub-list {
  background: linear-gradient(193deg, #FDF0DE 11.51%, #FDF0DD 51.38%, #FBDAD0 101.22%);
  @apply p-6 rem:rounded-[8px];
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-content .menu-sub-list ul {
  @apply w-full;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-content .menu-sub-list li[class*=current] a {
  @apply text-Primary-6 font-bold;
}
header .header-menu > ul > li.dropdown-product .dropdown-product-list .menu-content .menu-sub-list a {
  @apply block py-4 text-lg font-light text-[#757679] hover:font-bold transition-all duration-300 hover:text-Primary-6 border-b border-Neutral-200;
}
header .header-menu > ul > li.dropdown-product .tab-content-sub {
  background: transparent;
  @apply px-8 py-6 rounded-r-lg pl-4;
}
header .header-menu > ul > li.dropdown-product .tab-content-sub .menu-sub-child-list {
  @apply w-[40%] pr-4 lg:max-h-[45vh] lg:overflow-y-auto overflow-scroll-1;
}
header .header-menu > ul > li.dropdown-product .tab-content-sub .menu-sub-child-list ul {
  @apply flex flex-col gap-5;
}
header .header-menu > ul > li.dropdown-product .tab-content-sub .menu-sub-child-list ul li[class*=current] a {
  @apply text-Primary-6;
}
header .header-menu > ul > li.dropdown-product .tab-content-sub .menu-sub-child-list ul li a {
  @apply font-light text-[#757679] transition-all duration-300 hover:text-Primary-6 hover:underline;
}
header .header-menu > ul > li.dropdown-product .tab-content-sub .menu-sub-child-content {
  @apply w-[60%];
}
header .header-menu > ul > li.dropdown-product .item-news-content {
  @apply flex flex-col h-full;
}
header .header-menu > ul > li.dropdown-product .item-news-content .content {
  @apply flex-1;
}
header .header-menu > ul > li.dropdown-product .item-news-content .content .btn-style-primary {
  @apply mt-auto;
}
header .header-menu > ul > li > a {
  @apply bg-transparent;
  @apply flex items-center px-4 text-lg font-medium rounded-full h-full py-2 text-Primary-#02;
  @apply hover-gradient-primary transition-all duration-300 hover:text-white;
}
header .header-bottom .header-menu, header .header-bottom .header-booking {
  @apply lg:flex hidden;
}

.header-hambuger {
  @apply flex flex-col gap-2 ml-0 md:ml-11 lg:ml-11 xl:ml-[calc(58/19.2*1rem)] cursor-pointer z-10 relative lg:hidden justify-center;
}
.header-hambuger span {
  @apply transition-all duration-300 pointer-events-none;
  @apply w-7 h-1 bg-white;
}
.header-hambuger span:nth-child(1) {
  transform-origin: center left;
}
.header-hambuger span:nth-child(2) {
  @apply opacity-100;
}
.header-hambuger span:nth-child(3) {
  transform-origin: center left;
}
.header-hambuger #pulseMe {
  transform-origin: center center;
  @apply pointer-events-none;
  @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[38px] h-[38px] absolute;
  @apply bg-[#AF9481] -z-1;
}
.header-hambuger #pulseMe .bar {
  box-shadow: 0 0 0 #ffffff, 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 4px #ffffff;
  @apply absolute bg-white;
}
.header-hambuger #pulseMe .bar.left {
  animation: leftBar 2s linear infinite;
  @apply w-[1px];
}
.header-hambuger #pulseMe .bar.top {
  animation: topBar 2s linear infinite;
  @apply h-[1px] w-0;
}
.header-hambuger #pulseMe .bar.right {
  animation: rightBar 2s linear infinite;
  @apply w-[1px];
}
.header-hambuger #pulseMe .bar.bottom {
  animation: bottomBar 2s linear infinite;
  @apply h-[1px];
}

body.isOpenMenu header .header-hambuger span:nth-child(1) {
  rotate: 45deg;
  translate: 0.2083333333rem 0.15625rem;
}
body.isOpenMenu header .header-hambuger span:nth-child(2) {
  @apply opacity-0;
}
body.isOpenMenu header .header-hambuger span:nth-child(3) {
  rotate: -45deg;
  translate: 0.2083333333rem -0.0520833333rem;
}

@keyframes leftBar {
  0% {
    height: 0;
    left: 0;
    top: 100%;
  }
  20% {
    height: 100%;
    left: 0;
    top: 0;
  }
  40% {
    height: 0;
    left: 0;
    top: 0;
  }
}
@keyframes topBar {
  0%, 20% {
    left: 0;
    top: 0;
    width: 0;
  }
  40% {
    left: 0;
    top: 0;
    width: 100%;
  }
  60% {
    left: calc(100% - 1px);
    top: 0;
    width: 0;
  }
}
@keyframes rightBar {
  0%, 40% {
    height: 0;
    left: calc(100% - 1px);
    top: 0;
  }
  60% {
    height: 100%;
    left: calc(100% - 1px);
    top: 0;
  }
  80% {
    height: 0;
    left: calc(100% - 1px);
    top: 100%;
  }
}
@keyframes bottomBar {
  0%, 60% {
    left: 100%;
    top: 100%;
    width: 0;
  }
  80% {
    left: 0;
    top: 100%;
    width: 100%;
  }
  100% {
    left: 0;
    top: 100%;
    width: 0;
  }
}
.header-menu-mobile {
  @apply xl:hidden;
}
.header-menu-mobile[data-step-index="0"] .close i::before {
  content: "\f00d";
}
@media (max-width: 1199.98px) {
  .header-menu-mobile {
    @apply fixed top-0 left-0 w-full h-[100dvh] z-[999] bg-white block;
    @apply pointer-events-none translate-x-full overflow-hidden;
    transition: transform 0.6s cubic-bezier(0.75, 0, 0.175, 1), visibility 0.6s cubic-bezier(0.75, 0, 0.175, 1);
  }
}
.header-menu-mobile .header-title-close {
  background: linear-gradient(90deg, #fdf0de, #fdf0dd 50.76%, #fbdad0);
}
.header-menu-mobile .header-top {
  @apply flex items-start px-[15px] gap-[15px] mt-5;
}
.header-menu-mobile .header-menu > ul > li[class*=has-children] > a::after {
  content: "\f054";
  @apply inline-block font-awesome text-xl;
}
.header-menu-mobile .header-menu ul li > a {
  @apply flex items-center px-[15px] py-5 text-[18px] font-light border-b border-neutral-100 justify-between;
}
.header-menu-mobile .header-menu .menu-list a::after {
  content: "\f054";
  @apply inline-block font-awesome text-xl;
}
.header-menu-mobile .menu-mega-main {
  @apply fixed top-[50px] translate-x-full w-screen h-[calc(100svh-50px)] bg-white z-10 left-0 transition-all duration-300;
}
.header-menu-mobile .menu-mega-main.active {
  @apply translate-x-0;
}
.header-menu-mobile .menu-sub-list {
  @apply w-full;
}
.header-menu-mobile .menu-sub-list ul {
  @apply w-full;
}
.header-menu-mobile .tab-content-menu {
  @apply fixed top-[0px] translate-x-full w-screen h-[calc(100svh-0px)] bg-white z-10 left-0 transition-all duration-300 p-5;
  @apply block !important;
}
.header-menu-mobile .tab-content-menu .menu-sub-content {
  @apply hidden;
}
.header-menu-mobile .tab-content-menu.active {
  @apply translate-x-0;
}

body.isOpenMenu .header-menu-mobile {
  @apply pointer-events-auto translate-x-0;
}

.header-search-form {
  visibility: hidden;
  @apply fixed rem:top-[0px] left-0 w-full h-full bg-black bg-opacity-70 backdrop-blur-md z-[1000] flex justify-center items-center transition-all duration-300 opacity-0  pointer-events-none;
}
.header-search-form.active {
  @apply opacity-100 visible pointer-events-auto;
}
.header-search-form .wrap-form-search-product {
  max-width: 50rem;
  margin: 0 auto;
  @apply w-full;
}
.header-search-form .wrap-form-search-product .productsearchbox {
  @apply w-full flex relative flex-wrap;
}
.header-search-form .wrap-form-search-product .productsearchbox [class*=Module] {
  width: 100% !important;
}
.header-search-form .wrap-form-search-product .productsearchbox input {
  @apply flex-1 h-12.5 bg-white text-base px-7.5 outline-0 border-0 !important;
}
.header-search-form .wrap-form-search-product .productsearchbox button {
  @apply w-12.5 flex items-center justify-center  outline-0 border-0 border-l border-[#E0E0E0] bg-Neutral-100 text-Primary-#06 text-2xl;
}

footer {
  background: linear-gradient(90deg, #FDF0DE 0%, #FDF0DD 50.76%, #FBDAD0 100%);
  border-top: 2px solid #AF9481;
  @apply pt-15 pb-10;
}
footer .footer-logo img {
  @apply max-w-full;
}
footer .footer-social {
  @apply flex items-start gap-5;
}
footer .footer-social li img {
  @apply h-5 w-auto object-contain rem:max-w-[23px];
}
footer .title-footer {
  @apply text-[18px] lg:text-lg font-medium mb-5 lg:mb-3 leading-none;
}
footer .list-footer {
  @apply flex flex-col gap-3;
}
footer .list-footer li a {
  position: relative;
  transition: all 0.3s ease;
  background-image: linear-gradient(180deg, transparent 95%, #ff9612 0);
  background-repeat: no-repeat;
  background-size: 0 100%;
  @apply text-base font-light hover:text-Primary-6 pb-2;
}
footer .list-footer li a:hover {
  background-size: 100% 100%;
}
footer .item-contact i.fa-phone-volume {
  @apply rotate-[-45deg];
}
@media (max-width: 767.98px) {
  footer .col:nth-child(1) {
    order: 3;
  }
  footer .col:nth-child(2) {
    order: 4;
  }
  footer .col:nth-child(3) {
    order: 1;
  }
  footer .col:nth-child(4) {
    order: 2;
  }
}

.tool-cta {
  @apply z-[999];
}
.tool-cta .button-to-top {
  @apply hidden;
}
.tool-cta .button-to-top.active {
  @apply block;
}
.tool-cta .btn-cta {
  @apply px-3;
}
.tool-cta .btn-cta img {
  @apply max-w-full;
}
.tool-cta .btn-pulse::after {
  content: "";
  box-shadow: 0 0 0 0 rgba(255, 89, 0, 0.859);
  animation: pulse-red 2s infinite;
  @apply absolute-full rem:rounded-[50px];
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 89, 0, 0.6);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
.section-book input, .section-book textarea {
  @apply placeholder:text-Neutral-500 text-Neutral-500;
}
.section-book textarea {
  @apply w-full p-3 border border-Neutral-200 rounded-lg rem:h-[172px];
}

.section-page-faq .wrap-item-toggle .item-toggle {
  @apply cursor-pointer transition-all duration-300;
}
.section-page-faq .wrap-item-toggle .item-toggle .title {
  @apply rem:min-h-[80px] flex items-center gap-2  relative;
  @apply border-b border-transparent transition-all duration-300 pr-5;
}
.section-page-faq .wrap-item-toggle .item-toggle .title.active {
  @apply text-Primary-6;
}
.section-page-faq .wrap-item-toggle .item-toggle .title.active::after {
  @apply rotate-180;
}
.section-page-faq .wrap-item-toggle .item-toggle .title::after {
  content: "\f078";
  @apply font-awesome text-xl absolute font-light right-0;
}
.section-page-faq .wrap-item-toggle .item-toggle .content {
  @apply gradient-linear-2 px-4 py-3 rounded-lg;
  @apply hidden py-5;
}
.section-page-faq .wrap-item-toggle .item-toggle .content .wrap {
  @apply flex flex-wrap gap-base;
}
.section-page-faq .wrap-item-toggle .item-toggle .content .wrap .img {
  @apply md:w-[40%] w-full;
}
.section-page-faq .wrap-item-toggle .item-toggle .content .wrap .format-content {
  @apply flex-1;
}

.contact-page .text-normal {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
}
.contact-page .sub-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
.contact-page .title {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #F15A22;
}
.contact-page .form-group {
  background-color: #fff;
  border: 1px solid #E0E0E0;
  padding: 12px 20px;
}
.contact-page .btn-primary {
  background-color: #fff;
  color: #F15A22;
  border: 1px solid #F15A22;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.contact-page .btn-primary:hover {
  background-color: #F15A22;
  color: #fff;
}

.bannerProduct .img {
  background-image: url("../../img/bannerProduct.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.breadcrumb-wrapper {
  background-color: rgba(137, 137, 137, 0.1);
}

section .box-image {
  position: relative;
  aspect-ratio: 16/9;
  object-fit: cover;
  border: 1px solid #DCDCDC;
}
section .box-image:hover .img {
  transform: scale(1.05);
  transition: transform 0.4s ease-in-out;
}
section .box-image img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
}
section .box-image .title:hover {
  color: #8A3F40;
}
section .box-image .discount span {
  color: white;
  padding: 3px 10px;
  font-weight: 200;
  border-radius: 6px;
}
section .box-image .price i {
  color: white;
}

.staff-detail .staff-tab-box .wrap ul li.active a {
  @apply bg-Primary-6 text-white;
}
.staff-detail .staff-tab-box .wrap ul li a {
  @apply p-3 text-xl font-medium text-Primary-#02 bg-Neutral-100 px-5 rounded-full;
}

.staff-pagination span.active {
  @apply bg-[#471116] text-white;
}

.breadcum-nav li a {
  @apply text-Neutral-500 font-light leading-6;
}

.service-content-price {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
}

.service-pagination span {
  @apply w-10 h-10 rounded-full bg-neutral-50 flex items-center justify-center text-lg font-medium rem:leading-[26px];
}

.section-home-banner .swiper-pagination {
  @apply static flex md:justify-start justify-center;
}
.section-home-banner .swiper-pagination .swiper-pagination-bullet {
  @apply gradient-primary w-5 rem:rounded-[4px] transition-all duration-300;
}
.section-home-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply w-15;
}

.section-home-support .wrap-form {
  @apply grid-cols-2 grid gap-3;
}
.section-home-support .wrap-form .form-group input {
  @apply w-full rem:h-[56px] rounded-full border border-Neutral-400 px-5 text-Neutral-500 placeholder:text-Neutral-500 font-light;
}

.section-home-why-us {
  @apply relative;
}
.section-home-why-us::before {
  content: "";
  background-image: url("../img/4.png");
  background-size: 100% 275px;
  @apply absolute bottom-0 left-0 w-full rem:h-[275px];
}

.section-home-services.section-bg {
  background: linear-gradient(90deg, rgba(253, 240, 222, 0.4) 0%, rgba(253, 240, 221, 0.4) 50.76%, rgba(251, 218, 208, 0.4) 100%);
}

.section-home-tabs {
  @apply relative z-2;
}
.section-home-tabs:not(.not-bg)::before {
  content: "";
  background-image: url("../img/5.png");
  background-size: 100% 275px;
  background-repeat: no-repeat;
  background-position: center top;
  @apply absolute top-0 left-0 w-full rem:h-[275px] pointer-events-none -z-1;
}

.section-home-coupon {
  background: linear-gradient(90deg, #FDF0DE 0%, #FDF0DD 50.76%, #FBDAD0 100%);
}
.section-home-coupon .item-comp-coupon {
  box-shadow: 4px 4px 13.1px 0px rgba(0, 0, 0, 0.08);
}
.section-home-coupon .swiper-slide {
  @apply py-8 px-2;
}

.section-home-news .wrap-col-news:first-child .wrap-item-news {
  @apply relative;
}
.section-home-news .wrap-col-news:first-child .wrap-item-news:before {
  content: "";
  background: linear-gradient(90deg, #FFD2A3 0%, #FCDDBC 47.5%, #FFD2A3 100%);
  @apply absolute top-0 right-0 w-[1px] h-full rem:translate-x-[20px];
}

.section-about-value .wrap-item-toggle .item-toggle {
  @apply py-3 border-b border-Neutral-100;
}
.section-about-value .wrap-item-toggle .item-toggle .title {
  @apply flex items-center gap-2  relative text-xl;
  @apply transition-all duration-300 pr-5 cursor-pointer;
}
.section-about-value .wrap-item-toggle .item-toggle .title.active {
  @apply text-Primary-6;
}
.section-about-value .wrap-item-toggle .item-toggle .title.active::after {
  @apply rotate-180;
}
.section-about-value .wrap-item-toggle .item-toggle .title::after {
  content: "\f078";
  @apply font-awesome text-xl absolute font-light right-0;
}
.section-about-value .wrap-item-toggle .item-toggle .content {
  @apply py-3 rounded-lg hidden;
}

.section-about-history .swiper-pagination {
  @apply absolute flex-center top-full mt-5;
}
.section-about-history .swiper-pagination .swiper-pagination-bullet {
  @apply flex-1 w-auto h-1 bg-Primary-#04 rounded-full;
}
.section-about-history .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-Primary-6 rounded-full;
}
.section-about-history .wrap-slide-years {
  @apply relative;
}
.section-about-history .wrap-slide-years::before {
  content: "";
  @apply absolute top-[57%] md:top-[50%] -translate-y-1/2 left-0 w-full h-[1px] bg-Primary-#01;
}
.section-about-history .wrap-slide-years .swiper-slide:first-child .line-year .circle {
  @apply bg-white border-Primary-#01;
}
.section-about-history .wrap-slide-years .swiper-slide.swiper-slide-next .wrap-content .title-48 {
  @apply text-Primary-6;
}
.section-about-history .wrap-slide-years .swiper-slide.swiper-slide-next .content .line-year .dot {
  @apply bg-Primary-6;
}
.section-about-history .wrap-slide-years .swiper-slide.swiper-slide-next .content .line-year .line {
  @apply bg-gradient-to-b from-Primary-6 to-transparent !important;
}
@media (max-width: 767.98px) {
  .section-about-history .wrap-slide-years .swiper-slide .item-history .content {
    @apply rem:-translate-y-[calc(65px-15px)];
  }
}
@screen md {
  .section-about-history .wrap-slide-years .swiper-slide:nth-child(odd) .item-history .content {
    @apply rem:-translate-y-[calc(65px-15px)];
  }
  .section-about-history .wrap-slide-years .swiper-slide:nth-child(even) .item-history {
    @apply flex flex-col-reverse;
  }
  .section-about-history .wrap-slide-years .swiper-slide:nth-child(even) .item-history .content {
    @apply flex justify-end flex-col;
    @apply rem:translate-y-[calc(65px-15px)];
  }
  .section-about-history .wrap-slide-years .swiper-slide:nth-child(even) .item-history .wrap-content {
    @apply pt-0 pb-10;
  }
  .section-about-history .wrap-slide-years .swiper-slide:nth-child(even) .item-history .line {
    @apply bg-gradient-to-b from-Primary-#01 to-transparent;
  }
  .section-about-history .wrap-slide-years .swiper-slide:nth-child(even) .item-history .line-year {
    @apply flex flex-col-reverse;
  }
}

.section-home-coop .swiper-wrapper .swiper-slide {
  @apply p-2;
}
.section-home-coop .swiper-wrapper .swiper-slide .item-comp-coop-logo .div-ratio {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.08);
}
.section-home-coop .swiper-wrapper .swiper-slide .item-comp-coop-logo .div-ratio img {
  @apply max-w-full object-contain;
}

.section-about-space .swiper-slide.swiper-slide-active .img::after {
  @apply opacity-0;
}
.section-about-space .swiper-slide:not(.swiper-slide-active) img {
  mix-blend-mode: luminosity;
  filter: grayscale(100%);
}
.section-about-space .swiper-slide .img::after {
  content: "";
  @apply transition-all duration-300  absolute inset-0 bg-white/60 z-1 opacity-100;
}
.section-about-space .swiper-slide .img, .section-about-space .swiper-slide .content {
  @apply transition-all duration-300;
}

.section-about-prize .wrap-prize {
  @apply relative;
}
.section-about-prize .wrap-prize::after {
  content: "";
  @apply w-[280px] h-[280px] lg:rem:w-[506px] lg:rem:h-[506px] gradient-linear-2 rounded-full absolute top-1/2 -translate-y-[55%] left-full -translate-x-[75%];
}
.section-about-prize .wrap-prize::before {
  content: "";
  background-image: url("../img/prize-test.png");
  background-size: 100% 100%;
  @apply -translate-x-[40%];
  @apply absolute w-[100px] h-[210px] lg:rem:w-[182px] lg:rem:h-[373px] bottom-0 left-full z-10;
}

.box-date {
  @apply w-16 h-16 flex-center bg-Primary-6 text-white text-center flex-col leading-tight;
}
.box-date .date {
  @apply text-2xl font-bold;
}
.box-date .month {
  @apply text-xs font-bold;
}

.card-item {
  background: var(--Linear-4, linear-gradient(90deg, rgba(253, 240, 222, 0.4) 0%, rgba(253, 240, 221, 0.4) 50.76%, rgba(251, 218, 208, 0.4) 100%));
}

.section-product-detail .format-content table {
  @apply w-full table-fixed border-Neutral-100 border;
}
.section-product-detail .format-content table tr {
  @apply odd:bg-Neutral-100 bg-white;
}
.section-product-detail .format-content table tr td {
  @apply py-3 px-4;
}
.section-product-detail .product-slide-thumbs .swiper-slide .img {
  @apply lg:p-2 border border-Neutral-100;
}
.section-product-detail .product-slide-thumbs .swiper-slide.swiper-slide-thumb-active .img {
  @apply border-Primary-6;
}
.section-product-detail .product-slide-main .swiper-slide .img img {
  @apply object-contain;
}

section.section-product-detail.section-tabslet ul.tabslet-tab {
  @apply overflow-x-auto flex gap-5;
}
section.section-product-detail.section-tabslet ul.tabslet-tab li.active a {
  @apply bg-Primary-6 text-white;
}
section.section-product-detail.section-tabslet ul.tabslet-tab li a {
  @apply text-Neutral-500 bg-neutral-100 h-15 flex-center text-base md:text-xl px-5 font-bold hover:bg-Primary-6 hover:text-white transition-all duration-300 rounded-full;
}
section.section-product-detail.section-tabslet .tabslet-content {
  @apply bg-Neutral-100 lg:p-10 p-5;
}

.section-recruitment-detail .box-recruitment-detail-item table tr {
  @apply border-b border-Neutral-200;
}
.section-recruitment-detail .box-recruitment-detail-item table td {
  @apply py-4 first:whitespace-nowrap first:pr-5;
}

.form-input-file {
  @apply relative flex items-center;
  @apply w-full rem:h-[56px] rounded-full border border-Neutral-400 px-5 font-light;
}
.form-input-file input[type=file] {
  @apply absolute-full text-transparent z-10;
}
.form-input-file input[type=file]::-webkit-file-upload-button, .form-input-file input[type=file]::file-selector-button {
  @apply hidden !important;
}
.form-input-file .wpcf7-form-control-wrap {
  @apply static !important;
}
.form-input-file::before {
  content: "Input file CV";
  @apply text-base text-Primary-6 font-bold left-5;
}
.form-input-file .file-name {
  text-overflow: ellipsis;
  @apply w-full h-full rounded-full border border-transparent px-5 font-light absolute-full mt-0 bg-white text-Primary-6;
  @apply block px-5 line-clamp-1 place-content-center whitespace-nowrap;
}

.btn-swiper {
  @apply absolute	mx-10;
}
.btn-swiper.btn-prev {
  right: 100%;
}
.btn-swiper.btn-next {
  left: 100%;
}

.news-detail .format-content a {
  @apply break-words;
}

section.section-recruitment-list.section-3 table {
  @apply w-full;
}
section.section-recruitment-list.section-3 table th, section.section-recruitment-list.section-3 table td {
  @apply border border-white;
}
section.section-recruitment-list.section-3 table thead tr th {
  @apply text-xl text-white bg-Primary-6 font-bold px-5 py-3;
}
section.section-recruitment-list.section-3 table thead tr th:nth-child(2) {
  @apply text-left;
}
section.section-recruitment-list.section-3 table thead tr th:nth-child(1) {
  @apply text-center;
}
section.section-recruitment-list.section-3 table tbody tr:nth-child(odd) {
  @apply bg-Neutral-100;
}
section.section-recruitment-list.section-3 table tbody tr td {
  @apply text-base px-5 py-3;
}
section.section-recruitment-list.section-3 table tbody tr td:nth-child(1) {
  @apply text-center;
}
section.section-recruitment-list.section-3 table tbody tr td:nth-child(2) {
  @apply text-left;
}
section.section-recruitment-list.section-3.section-3 table tbody tr:nth-child(n+5) {
  @apply hidden;
}

.service-1 .edge-image {
  mix-blend-mode: luminosity;
}

.service-6 .wrap-item-toggle .item-toggle .title {
  @apply rem:min-h-[52px] flex items-start gap-2  relative;
  @apply transition-all duration-300 pr-5 cursor-pointer;
}
.service-6 .wrap-item-toggle .item-toggle .title.active {
  @apply text-Primary-6;
}
.service-6 .wrap-item-toggle .item-toggle .title.active::after {
  @apply rotate-180;
}
.service-6 .wrap-item-toggle .item-toggle .title::after {
  content: "\f078";
  @apply font-awesome text-xl absolute font-light right-0;
}
.service-6 .wrap-item-toggle .item-toggle .content {
  @apply gradient-linear-2 px-4 py-3 rounded-lg;
}
.service-6 .frm-submit button {
  @apply flex;
}

.service-7 .card-post .img a::before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
  @apply absolute left-0 bottom-0 w-full rem:h-[153px] z-2;
}