.btn
	@apply cursor-pointer select-none transition-all duration-300 z-10
	&.btn-sw-next
		@apply absolute top-1/2 right-[-40px] translate-y-[-50%]
	&.btn-sw-prev
		@apply absolute top-1/2 left-[-40px] translate-y-[-50%]
	&.btn-style-primary
		@apply px-6 py-2 rounded-full text-white flex-center gradient-primary
		&.big
			@apply rem:h-[52px] text-lg
	&.btn-style-sw-3
		@apply flex-center w-12 h-12 bg-black/50 hover:bg-black
		&::before
			@apply font-awesome text-white text-xl
		&.btn-prev
			&::before
				content: '\f053'
		&.btn-next
			&::before
				content: '\f054'
	&.btn-sw-1
		@apply flex-center w-13 h-13 lg:w-15 lg:h-15 rounded-full border border-white hover:border-Primary-6 hover:bg-Primary-6
		&.primary
			@apply border-Primary-6
			&.btn-prev,&.btn-next
				&::before
					@apply text-Primary-6
		&.black
			@apply border-Neutral-500 hover:border-Primary-6
			&.btn-prev,&.btn-next
				&::before
					@apply text-Neutral-500
		&:hover
			&.btn-prev,&.btn-next
				&::before
					@apply text-white
		&.btn-prev,&.btn-next
			&::before
				@apply font-thin font-awesome text-[18px] lg:rem:text-[32px] text-white transition-all duration-300
		&.btn-prev
			&::before
				content: '\f060'
		&.btn-next
			&::before
				content: '\f061'
	&.btn-outline
		@apply border border-dashed border-Primary-6 rounded-full h-12 px-8 flex-center text-Primary-6 font-medium
		@apply hover-gradient-primary hover:text-white
	&.btn-hotline
		img
			animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out

@keyframes coccoc-alo-circle-img-anim
	0%
		transform: rotate(0) scale(1) skew(1deg)
	10%
		transform: rotate(-25deg) scale(1) skew(1deg)
	20%
		transform: rotate(25deg) scale(1) skew(1deg)
	30%
		transform: rotate(-25deg) scale(1) skew(1deg)
	40%
		transform: rotate(25deg) scale(1) skew(1deg)
	50%
		transform: rotate(0) scale(1) skew(1deg)
	100%
		transform: rotate(0) scale(1) skew(1deg)
