.service-6
	.wrap-item-toggle
		.item-toggle
			.title
				@apply rem:min-h-[52px] flex items-start gap-2  relative
				@apply transition-all duration-300 pr-5 cursor-pointer
				&.active
					@apply text-Primary-6
					&::after
						@apply rotate-180
				&::after
					content: "\f078"
					@apply font-awesome text-xl absolute font-light right-0
			.content
				@apply gradient-linear-2 px-4 py-3 rounded-lg
	.frm-submit
		button
			@apply flex
