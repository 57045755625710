header
	background: linear-gradient(90deg, #FDF0DE 0%, #FDF0DD 50.76%, #FBDAD0 100%)
	@apply sticky top-0 left-0 w-full z-[900]
	.header-language
		@apply relative ml-6
		&:hover
			ul
				@apply opacity-100 pointer-events-auto
		.js-wpml-ls-legacy-dropdown
			@apply w-auto #{!important}
			&>ul
				&>li
					&>a
						@apply flex items-center gap-2 rem:text-[14px] w-auto bg-transparent px-0 py-0 text-Primary-#02 border-none flex-row-reverse #{!important}
						&::after
							@apply hidden
						&::before
							content: '\f0d7'
							@apply font-awesome rem:text-[8px] font-black
				&>ul
					@apply absolute top-full left-0 shadow-md opacity-0 pointer-events-none transition-all duration-300
					&>li
						&>a
							@apply flex items-center gap-2 rem:text-[14px] bg-white rounded-sm px-2 py-1 leading-none hover:text-Primary-6 hover:bg-neutral-50
	.header-menu
		@apply flex items-center lg:gap-3 xl:gap-6
		&>ul
			@apply flex items-center lg:gap-3 xl:gap-6
			&>li
				&[class*='current']
					a
						@apply text-white
						&::before
							@apply opacity-100

				&.dropdown-product
					.menu-mega-main
						@apply opacity-0 pointer-events-none transition-all duration-300
						&.active
							@apply opacity-100 pointer-events-auto
					.dropdown-product-list
						@apply absolute top-full left-0 w-full bg-white rem:min-h-[618px] px-10 py-7 rounded-b-lg shadow-lg flex
						.close
							@apply absolute top-2 right-2 p-4 rem:w-[30px] rem:h-[30px] flex-center bg-[#FEF9F1] rounded-full hover:bg-Primary-6 transition-all duration-300 cursor-pointer
							&:hover
								&::before
									@apply text-white
							&::before
								content: '\f00d'
								@apply font-awesome rem:text-[14px] text-Primary-6
						.tab-content-menu
							@apply h-full flex flex-col
						.menu-list
							@apply w-[20%] pr-4 border-r border-Neutral-200
							ul
								@apply flex flex-col gap-4
								li
									&[class*='current']
										a
											@apply text-Primary-6
									a
										@apply hover:text-Primary-6 transition-all duration-300 text-[#757679]
						.menu-content
							@apply w-[80%] pl-4 flex flex-col
							.tab-content
								background: linear-gradient(90deg, rgba(253, 240, 222, 0.40) 0%, rgba(253, 240, 221, 0.40) 50.76%, rgba(251, 218, 208, 0.40) 100%)
								@apply flex-1 flex rounded-r-lg
							.menu-sub-list
								background: linear-gradient(193deg, #FDF0DE 11.51%, #FDF0DD 51.38%, #FBDAD0 101.22%)
								@apply p-6 rem:rounded-[8px]
								ul
									@apply w-full
								li
									&[class*='current']
										a
											@apply text-Primary-6 font-bold
								a
									@apply block py-4 text-lg font-light text-[#757679] hover:font-bold transition-all duration-300 hover:text-Primary-6 border-b border-Neutral-200
					.tab-content-sub
						background: transparent
						@apply px-8 py-6 rounded-r-lg pl-4
						.menu-sub-child-list
							@apply w-[40%] pr-4 lg:max-h-[45vh] lg:overflow-y-auto overflow-scroll-1
							ul
								@apply flex flex-col gap-5
								li
									&[class*='current']
										a
											@apply text-Primary-6
									a
										@apply font-light text-[#757679] transition-all duration-300 hover:text-Primary-6 hover:underline
						.menu-sub-child-content
							@apply w-[60%]
					.item-news-content
						@apply flex flex-col h-full
						.content
							@apply flex-1
							.btn-style-primary
								@apply mt-auto

				&>a
					@apply bg-transparent
					@apply flex items-center px-4 text-lg font-medium rounded-full h-full py-2 text-Primary-#02
					@apply hover-gradient-primary transition-all duration-300 hover:text-white
	.header-bottom
		.header-menu,.header-booking
			@apply lg:flex hidden
