.section-product-detail
	.format-content
		table
			@apply w-full table-fixed border-Neutral-100 border
			tr
				@apply odd:bg-Neutral-100 bg-white
				td
					@apply py-3 px-4
	.product-slide-thumbs
		.swiper-slide
			.img
				@apply lg:p-2 border border-Neutral-100
			&.swiper-slide-thumb-active
				.img
					@apply border-Primary-6
	.product-slide-main
		.swiper-slide
			.img
				img
					@apply object-contain
