.global-breadcrumb
	@apply border-b border-Primary-#04
	p
		@apply h-[calc(48/1920*100rem)] flex items-center overflow-auto
		a
			&:first-child
				@apply text-0
				&::before
					content: '\f015'
					@apply font-awesome text-sm
		a,span
			@apply text-sm text-[#333333] whitespace-nowrap
		span.separator
			@apply text-0 inline-flex
			&::before
				content: "/"
				@apply font-normal text-sm mx-2

