.section-about-prize
	.wrap-prize
		@apply relative
		&::after
			content: ''
			@apply w-[280px] h-[280px] lg:rem:w-[506px] lg:rem:h-[506px] gradient-linear-2 rounded-full absolute top-1/2 -translate-y-[55%] left-full -translate-x-[75%]
		&::before
			content: ''
			background-image: url('../img/prize-test.png')
			background-size: 100% 100%
			@apply -translate-x-[40%]
			@apply absolute w-[100px] h-[210px] lg:rem:w-[182px] lg:rem:h-[373px] bottom-0 left-full z-10
