.btn-swiper
	@apply absolute	mx-10
	&.btn-prev
		right: 100%
	&.btn-next
		left: 100%
.news-detail
	.format-content
		a
			@apply break-words
