.title-64
	@apply text-4xl md:text-5xl lg:text-6xl xl:text-64 font-bold uppercase leading-normal
.title-40
	@apply text-3xl lg:text-5xl font-bold leading-tight
.title-48
	@apply text-3xl md:text-5xl lg:text-6xl font-bold leading-tight
.title-section
	@apply px-10 py-3 text-white capitalize rounded-full inline-block bg-Primary-6
.title-24
	@apply text-2xl text-Neutral-900 leading-tight
.title-32
	@apply lg:text-32 text-2xl font-bold leading-tight
