.section-about-space
	.swiper-slide
		&.swiper-slide-active
			.img
				&::after
					@apply opacity-0
		&:not(.swiper-slide-active)
			img
				mix-blend-mode: luminosity
				filter: grayscale(100%)
		.img
			&::after
				content: ''
				@apply transition-all duration-300  absolute inset-0 bg-white/60 z-1 opacity-100
		.img,.content
			@apply transition-all duration-300
