.breadcum-nav
	li
		a
			@apply text-Neutral-500 font-light leading-6
.service-content-price
	background: rgba(255, 255, 255, 0.15)
	backdrop-filter: blur(2px)
.service-pagination
	span
		@apply w-10 h-10 rounded-full bg-neutral-50 flex items-center justify-center text-lg font-medium rem:leading-[26px] 
		
